import _encode from "./encode.js";
import _decode from "./decode.js";
import _length from "./length.js";
var exports = {};
exports = {
  encode: _encode,
  decode: _decode,
  encodingLength: _length
};
export default exports;
export const encode = exports.encode;