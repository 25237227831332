var exports = {};
exports = encode;
var MSB = 128,
    REST = 127,
    MSBALL = ~REST,
    INT = Math.pow(2, 31);

function encode(num, out, offset) {
  out = out || [];
  offset = offset || 0;
  var oldOffset = offset;

  while (num >= INT) {
    out[offset++] = num & 255 | MSB;
    num /= 128;
  }

  while (num & MSBALL) {
    out[offset++] = num & 255 | MSB;
    num >>>= 7;
  }

  out[offset] = num | 0;
  encode.bytes = offset - oldOffset + 1;
  return out;
}

export default exports;